<template>
	<div v-if="oneCompany && selectedCompanyImage" class="h100 w-100">
		<div
			v-if="
				oneCompany.images.length == 1 &&
					((selectedCompanyImage.fileType == 'video' &&
						youtube_parser(selectedCompanyImage.link)) ||
						selectedCompanyImage.fileType != 'video' ||
						(selectedCompanyImage.fileType == 'video' &&
							is_vimeo(selectedCompanyImage.link)))
			"
			class="h100"
		>
			<div class="image__box image__box--l">
				<youtube
					v-if="
						selectedCompanyImage.fileType == 'video' &&
							!is_vimeo(selectedCompanyImage.link) &&
							youtube_parser(selectedCompanyImage.link)
					"
					:video-id="youtube_parser(selectedCompanyImage.link)"
				></youtube>
				<vimeo-player
					v-if="
						selectedCompanyImage.fileType == 'video' &&
							is_vimeo(selectedCompanyImage.link)
					"
					:video-id="get_vimeo_id(selectedCompanyImage.link)"
				></vimeo-player>
				<img
					:src="selectedCompanyImage.link"
					v-if="selectedCompanyImage.fileType != 'video'"
				/>
			</div>
		</div>
		<div
			v-if="oneCompany.images.length > 1"
			class="row__start row__start--stretch h100 image__scrollBox"
		>
			<div
				class="row__start--flex1 column__start scrollbar"
				:class="$route.query.app == 1 ? 'w-25' : 'max-w-25'"
			>
				<div
					v-for="image in oneCompany.images"
					:key="image.id"
					class="image__container pointer"
					:class="
						image.id == selectedCompanyImage.id
							? 'image__container--active'
							: ''
					"
					@click.stop="changeCompanyImage(image)"
					v-show="
						(image.fileType == 'video' &&
							youtube_parser(image.link)) ||
							image.fileType != 'video' ||
							(image.fileType == 'video' && is_vimeo(image.link))
					"
				>
					<youtube
						v-if="
							image.fileType == 'video' &&
								youtube_parser(image.link) &&
								!is_vimeo(image.link)
						"
						:video-id="youtube_parser(image.link)"
					>
					</youtube>
					<vimeo-player
						v-if="image.fileType == 'video' && is_vimeo(image.link)"
						:video-id="get_vimeo_id(image.link)"
						style="height: 10vh"
					></vimeo-player>
					<div
						class="absolute--overlay"
						v-if="image.fileType == 'video'"
						@click.stop="changeCompanyImage(image)"
					></div>

					<img v-if="image.fileType != 'video'" :src="image.link" />
				</div>
			</div>
			<div class="row__start--flex3">
				<div
					class="image__box image__box--l"
					:class="
						selectedCompanyImage.fileType == 'video' &&
						!is_vimeo(selectedCompanyImage.link)
							? ''
							: 'image__box--hide'
					"
				>
					<!-- <youtube
						v-if="
							selectedCompanyImage.fileType == 'video' &&
								!is_vimeo(selectedCompanyImage.link) &&
								youtube_parser(selectedCompanyImage.link) &&
								
				"
						:video-id="youtube_parser(selectedCompanyImage.link)"
					></youtube> -->
					<LazyYoutube
						v-if="
							selectedCompanyImage.fileType == 'video' &&
								!is_vimeo(selectedCompanyImage.link)
						"
						ref="lazyVideo"
						:src="selectedCompanyImage.link"
						max-width="95%"
						aspect-ratio="16:9"
						thumbnail-quality="standard"
					/>
					<!-- <iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/yAoLSRbwxL8"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					></iframe> -->
				</div>
				<div
					class="image__box image__box--l"
					:class="
						selectedCompanyImage.fileType == 'video' &&
						is_vimeo(selectedCompanyImage.link)
							? ''
							: 'image__box--hide'
					"
				>
					<!-- <vimeo-player
						style="height: 100%"
						:video-id="get_vimeo_id(selectedCompanyImage.link)"
						v-if="
							selectedCompanyImage.fileType == 'video' &&
								is_vimeo(selectedCompanyImage.link)
						"
					></vimeo-player> -->
					<LazyVimeo
						v-if="
							selectedCompanyImage.fileType == 'video' &&
								is_vimeo(selectedCompanyImage.link)
						"
						ref="vimeoLazyVideo"
						:src="selectedCompanyImage.link"
						max-width="95%"
						aspect-ratio="16:9"
						thumbnail-quality="standard"
					/>
				</div>
				<div
					:class="
						selectedCompanyImage.fileType != 'video'
							? ''
							: 'image__box--hide'
					"
					class="image__box image__box--l"
				>
					<a @click="showLightbox = true">
						<img
							:src="selectedCompanyImage.link"
							v-if="selectedCompanyImage.fileType != 'video'"
						/>
					</a>
				</div>
				<div>
					<div
						class="lightbox"
						id="images"
						v-if="showLightbox"
						@click.self="showLightbox = false"
					>
						<span @click="previousImage()">
							<ArrowLeftDefaultSvg />
						</span>
						<div v-if="selectedCompanyImage.fileType == 'image'">
							<span>
								<img :src="selectedCompanyImage.link" />
							</span>
						</div>
						<div
							v-if="
								selectedCompanyImage.fileType == 'video' &&
									is_vimeo(selectedCompanyImage.link)
							"
						>
							<!-- <vimeo-player
								style="height: 100%"
								:video-id="get_vimeo_id(selectedCompanyImage.link)"
							></vimeo-player> -->
							<LazyVimeo
								v-if="
									selectedCompanyImage.fileType == 'video' &&
										is_vimeo(selectedCompanyImage.link)
								"
								ref="vimeoLazyVideo"
								:src="selectedCompanyImage.link"
								max-width="95%"
								aspect-ratio="16:9"
								thumbnail-quality="standard"
							/>
							<!-- <iframe
								width="560"
								height="315"
								:src="selectedCompanyImage.link"
								title="Vimeo video player"
								frameborder="0"
							/> -->
						</div>
						<div
							v-if="
								selectedCompanyImage.fileType == 'video' &&
									!is_vimeo(selectedCompanyImage.link)
							"
							class="embed-container"
						>
							<!-- <youtube
								:video-id="youtube_parser(selectedCompanyImage.link)"
							></youtube> -->
							<LazyYoutube
								v-if="
									selectedCompanyImage.fileType == 'video' &&
										!is_vimeo(selectedCompanyImage.link)
								"
								ref="youtubeLazyVideo"
								:src="selectedCompanyImage.link"
								max-width="95%"
								aspect-ratio="16:9"
								thumbnail-quality="standard"
							/>

							<!-- <iframe
								width="560"
								height="315"
								src="https://www.youtube.com/embed/yAoLSRbwxL8"
								title="YouTube video player"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							></iframe> -->
						</div>
						<span @click.prevent="nextImage()">
							<ArrowRightDefaultSvg />
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from "vuex";
	import { LazyYoutube, LazyVimeo } from "vue-lazytube";
	export default {
		name: "CompanyImages",
		components: {
			LazyYoutube,
			LazyVimeo,
		},
		data() {
			return {
				isSwitching: false,
				showLightbox: false,
			};
		},
		methods: {
			...mapActions([]),
			youtube_parser(url) {
				var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
				var match = url.match(regExp);
				return match && match[7].length == 11 ? match[7] : false;
			},
			is_vimeo(url) {
				return url.includes("vimeo");
			},
			get_vimeo_id(url) {
				return /[^/]*$/.exec(url)[0];
			},
			changeCompanyImage(image) {
				console.log("Change company");
				this.$store.commit("setSelectedCompanyImage", image);
			},
			nextImage() {
				const media = this.oneCompany.images;
				var index = this.selectedCompanyImage.id;
				if (index == this.oneCompany.images.length - 1) {
					index = 0;
				} else {
					index += 1;
				}
				this.changeCompanyImage(media[index]);
			},
			previousImage() {
				const media = this.oneCompany.images;
				var index = this.selectedCompanyImage.id;
				if (index == 0) {
					index = this.oneCompany.images.length - 1;
				} else {
					index -= 1;
				}
				this.changeCompanyImage(media[index]);
			},
		},
		created() {},
		computed: {
			...mapGetters(["oneCompany", "selectedCompanyImage"]),
		},
		mounted() {},
	};
</script>

<style scoped>
	img {
		object-fit: contain;
	}
	.lightbox {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		z-index: 999;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 5em;
		background: rgba(0, 0, 0, 0.9);
	}
	@media (max-width: 900px) {
		.lightbox {
			padding: 1em;
		}
	}
	@media (max-width: 600px) {
		.lightbox span {
			padding: 0;
		}
	}
	.lightbox img {
		width: 100%;
		cursor: default;
		border-radius: 6px;
		max-height: 70vh;
	}
	.lightbox div {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lightbox span {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 2em;
	}
	.lightbox LazyYoutube,
	.lightbox LazyVimeo {
		max-height: 70vh;
		max-width: 70vh;
	}
</style>
